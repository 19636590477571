<template>
  <div class="grid-x">
    <div class="cell small-12 medium-8 bg-grey pb-5">
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 medium-10 medium-offset-1 mb pt-5">
          <h4 class="text-white">
            Budget Impact Model
          </h4>
          <h2 style="position:relative;top:-10px" class="text-white">
            Link Generator
          </h2>
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 medium-10 medium-offset-1 mb pt-5">
          <h2 class="text-white">CREATE:</h2>
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 medium-4 medium-offset-1 mb pt-5">
          <router-link to="/add-timed-link">
            <button class="button white expanded">a timed link</button>
          </router-link>
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 medium-10 medium-offset-1 mb pt-80">
          <p class="text-white text-small">
            Biogen-192046 Date of preparation: January 2023
          </p>
        </div>
      </div>
    </div>
    <div class="cell small-12 medium-4">
      <div :style="{ height: viewportHeight + 'px' }" class="relative">
        <img class="top-logo" src="../../assets/logos/biogen_logo.svg" alt="" />
        <img class="bottom-logo" src="../../assets/logos/Byooviz_logo.png" alt="" />
      </div>
    </div>
    <app-message
      v-if="showMessage === true"
      :messageText="message"
      :link="false"
      :linkText="false"
      @closeMessage="showMessage = false" />
  </div>
</template>

<script>
import Message from '@/components/Messages/Message.vue';
import axios from '../../axios';

export default {
  name: 'Links',
  components: {
    appMessage: Message,
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    },
  },
  data() {
    return {
      links: [],
      message: '',
      showMessage: false,
      viewportHeight: 0,
    };
  },
  methods: {
    getLinks() {
      axios.get(`/links/getLinks.json?token=${this.token}&user=${this.userid}`)
        .then((response) => {
          this.links = response.data.links;
        })
        .catch(() => {
          this.message = 'Sorry, something went wrong.';
          this.showMessage = true;
        });
    },
  },
  mounted() {
    this.viewportHeight = window.innerHeight;
    // this.getLinks();
  },
};
</script>
